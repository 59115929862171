<template>
  <div id="contractList">
    <Back :title="title"></Back>
    <!-- 搜索 -->
    <van-search
      v-model="params.kw"
      placeholder="请输入房源和客户名"
      show-action
    >
      <template #action>
        <div @click="onKeyWord">确认搜索</div>
      </template>
    </van-search>
    <!-- 列表 -->
    <main>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <section class="block-item" v-for="item in list" :key="item.contractId">
          <div class="block-title van-hairline--bottom">
            <div class="title-left"><i></i>{{ item.tenantName }}</div>
            <div
              class="title-right theme-color"
              @click="linkTo(item.contractId)"
            >
              查看详情
            </div>
          </div>
          <div class="block-content">
            <p>
              <span class="info-color">房源</span
              ><span
                >{{ item.resourceDtos[0].unitName }}-{{
                  item.resourceDtos[0].floorName
                }}-{{ item.resourceDtos[0].resourceName }}</span
              >
            </p>
            <p>
              <span class="info-color">租期</span
              ><span
                >{{ formatterDate(item.effDate, "yyyy-MM-dd") }}~{{
                  formatterDate(item.expDate, "yyyy-MM-dd")
                }}</span
              >
            </p>
            <p>
              <span class="info-color">租金单价</span
              ><span>{{ item.unitPrice || "暂无" }}</span>
            </p>
            <p>
              <span class="info-color">合同状态</span
              ><span>{{ contractStatusObj[item.contractStatus] }}</span>
            </p>
            <p>
              <span class="info-color">审批状态</span
              ><span>{{ approveStatusObj[item.approveStatus] || "暂无" }}</span>
            </p>
            <p
              v-if="
                (item.contractStatus == 1 && item.approveStatus == 3) ||
                (item.contractStatus == 1 && item.approveStatus == 6)
              "
            >
              <span class="info-color"></span
              ><van-button
                class="exit"
                plain
                type="info"
                @click="linkToExit(item.contractId)"
                >退租</van-button
              >
            </p>
          </div>
        </section>
      </van-list>
    </main>
  </div>
</template>

<script>
import Back from "@/components/back";
import { getContractList } from "@/api/contract/contract";
import { formatterDate } from "@/utils/utils";
import { contractStatusObj, approveStatusObj } from "@/db/contract";
export default {
  components: { Back },
  data() {
    return {
      formatterDate,
      contractStatusObj,
      approveStatusObj,
      title: "", //合同类型名
      params: {
        kw: "",
        pageNo: 0,
        approveStatusSet: [],
        contractStatusSet: [],
      },
      list: [],
      loading: false,
      finished: false, //是否结束加载，切换状态后记得开启
    };
  },
  created() {
    const { title, type } = this.$route.query;
    this.title = title + "列表";
    if (type == 1) {
      this.params.approveStatusSet = [1, 4, 7, 10];
      this.params.contractStatusSet = [];
    } else if (type == 2) {
      this.params.approveStatusSet = [];
      this.params.contractStatusSet = [1];
    } else if (type == 3) {
      this.params.approveStatusSet = [];
      this.params.contractStatusSet = [0];
    }
  },
  methods: {
    //  【请求】合同列表
    getContractList() {
      let data = this.params;
      getContractList(data).then((res) => {
        console.log("res: ", res);
        if (res.data) {
          this.list = [...this.list, ...res.data.content];
          this.loading = false;
          if (this.list.length >= res.data.total) {
            this.finished = true;
          }
        } else {
          this.loading = false;
          this.finished = true;
        }
      });
    },

    // 下拉加载
    onLoad() {
      this.params.pageNo++;
      this.getContractList();
    },

    // 【监听】关键字查询
    onKeyWord() {
      this.list = [];
      this.params.pageNo = 1;
      this.finished = false;
      this.getContractList();
    },

    // 【监听】查看详情
    linkTo(contractId) {
      this.$router.push({
        path: "/contract/contractInfo",
        query: { contractId },
      });
    },

    // 【监听】退租
    linkToExit(contractId) {
      this.$router.push({
        path: "/contract/contractExit",
        query: { contractId },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";
#contractList {
  height: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
  overflow: auto;
}
// 大盒子边距
.van-list {
  box-sizing: border-box;
  border-left: 0.15rem $bg-color solid;
  border-right: 0.15rem $bg-color solid;
}

// 文本模块
.block-item {
  border-top: $bg-color 0.15rem solid;
  display: flex;
  flex-direction: column;
  padding: 0 0.16rem 0.16rem 0.16rem;
  box-sizing: border-box;
  > .block-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.1rem 0;
    box-sizing: border-box;
    .title-left {
      display: flex;
      align-items: center;
      > i {
        display: inline-block;
        background: $theme-color;
        width: 0.04rem;
        height: 0.15rem;
        border-radius: 0 0.04rem 0.04rem 0;
        margin-right: 0.1rem;
      }
    }
    .title-right {
      width: 0.8rem;
      text-align: center;
    }
  }
  // 内容区上边距
  .block-content {
    margin-top: 0.1rem;
    > p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.02rem 0;
    }
  }
}
.exit {
  width: 0.8rem;
  height: 0.28rem;
  margin-top: 0.05rem;
}
</style>